:root {
  --space-unit: 1rem;
  --size-unit: 1rem;
  --max-character-length: 80ch;
  --text-underline-offset: 3px;
  --font-line-height-heading: 1.3;
  --font-line-height-body: 1.5;
  --font-size-h4: clamp(1.125rem, 2vw + 1rem, 1.25rem);
  --font-size-h3: clamp(1.25rem, 1.5vw + 1rem, 1.5rem);
  --font-size-h2: clamp(1.5rem, 2vw + 1rem, 1.75rem);
  --font-size-h1: clamp(1.75rem, 2vw + 1rem, 2.25rem);
  --font-family-body: 'Lato', 'Lucida Grande', Tahoma, Sans-Serif;
  --font-weight-700: 700;
  --font-weight-400: 400;
  --container-padding-increment: 5%;
  --container-max-width: 1120px;
  --color-grey-600: #949496;
  --color-grey-500: #cccccc;
  --color-grey-200: #f2f2f2;
  --color-info: #3455a5;
  --color-success: #348179;
  --color-error: #e22252;
  --color-warning: #f0ad4e;
  --color-green-700: #348179;
  --color-green-600: #409e93;
  --color-green-500: #4cbaac;
  --color-green-200: #b7e3de;
  --color-pink-500: #c1228f;
  --color-purple-500: #76489d;
  --color-purple-100: #e7ddf2;
  --color-red-500: #e42251;
  --color-blue-800: #233870;
  --color-blue-700: #253b75;
  --color-blue-500: #3555a6;
  --color-blue-100: #d6dded;
  --color-charcoal-500: #3b3b3c;
  --color-charcoal-300: #89898a;
  --color-white: #ffffff;
  --btn-border-radius: 999px;
  --btn-size-max-width: 400px;
  --btn-text-decoration: none;
  --btn-font-size: 1rem;
  --border-radius-button: 999px;
  --border-radius-round: 50%;
  --border-radius-xxl: 3rem;
  --border-radius-xl: 2rem;
  --border-radius-lg: 1rem;
  --border-radius-md: 0.75rem;
  --border-radius-sm: 0.5rem;
  --border-radius-xs: 0.25rem;
  --space-xxxxl: calc(3.5 * var(--space-unit));
  --space-xxxl: calc(3 * var(--space-unit));
  --space-xxl: calc(2.5 * var(--space-unit));
  --space-xl: calc(2 * var(--space-unit));
  --space-lg: calc(1.5 * var(--space-unit));
  --space-md: calc(1.25 * var(--space-unit));
  --space-base: calc(1 * var(--space-unit));
  --space-sm: calc(0.75 * var(--space-unit));
  --space-xs: calc(0.5 * var(--space-unit));
  --space-xxs: calc(0.25 * var(--space-unit));
  --size-tap-target: calc(3 * var(--size-unit));
  --size-xxxxl: calc(3.5 * var(--size-unit));
  --size-xxxl: calc(3 * var(--size-unit));
  --size-xxl: calc(2.5 * var(--size-unit));
  --size-xl: calc(2 * var(--size-unit));
  --size-lg: calc(1.5 * var(--size-unit));
  --size-md: calc(1.25 * var(--size-unit));
  --size-base: calc(1 * var(--size-unit));
  --size-sm: calc(0.75 * var(--size-unit));
  --size-xs: calc(0.5 * var(--size-unit));
  --size-xxs: calc(0.25 * var(--size-unit));
  --font-size-sm: calc(0.875 * var(--size-unit));
  --font-size-base: var(--size-unit);
  --font-weight-heading: var(--font-weight-700);
  --font-weight-body: var(--font-weight-400);
  --color-text-body: var(--color-charcoal-500);
  --color-product-short-course: var(--color-pink-500);
  --color-product-degree: var(--color-purple-500);
  --color-product-subject: var(--color-green-500);
  --color-product: var(--color-blue-800);
  --color-disabled: var(--color-charcoal-300);
  --color-interactive: var(--color-blue-500);
  --color-inverse: var(--color-white);
  --color-accent: var(--color-blue-500);
  --color-button-secondary: var(--color-blue-500);
  --color-button-primary: var(--color-red-500);
  --color-border-inverse: var(--color-white);
  --color-border-interactive: var(--color-blue-500);
  --color-border-decorative: var(--color-grey-500);
  --color-border-default: var(--color-grey-600);
  --color-bg-charcoal: var(--color-charcoal-500);
  --color-bg-white: var(--color-white);
  --color-bg-pink: var(--color-pink-500);
  --color-bg-purple: var(--color-purple-100);
  --color-bg-green-light: var(--color-green-200);
  --color-bg-green: var(--color-green-400);
  --color-bg-red: var(--color-red-500);
  --color-bg-blue-dark: var(--color-blue-800);
  --color-bg-blue-light: var(--color-blue-100);
  --color-bg-blue-pale: var(--color-blue-50);
  --color-bg-blue: var(--color-blue-500);
  --color-bg-grey-light: var(--color-grey-200);
  --color-bg-body: var(--color-white);
  --color-bg: var(--color-white);
  --btn-font-weight: var(--font-weight-400);
  --border-radius-curve: var(--border-radius-xl);
  --border-radius-badge: var(--border-radius-lg);
  --border-radius-interactive: var(--border-radius-sm);
  --border-radius-tag: var(--border-radius-xs);
  --border-radius-card: var(--border-radius-sm);
  --border-radius-input: var(--border-radius-sm);
  --section-padding-block: clamp(var(--space-lg), 4vw, var(--space-xxl));
  --input-color-border: var(--color-border-default);
  --input-color-bg: var(--color-bg);
  --font-size-h5: var(--size-base);
  --font-size-xxxl: min(max(var(--size-xxl),4vw),var(--size-xxxl));
  --font-size-xxl: min(max(var(--size-xl),4vw),var(--size-xxl));
  --font-size-xl: min(max(var(--size-lg),4vw),var(--size-xl));
  --font-size-lg: min(max(var(--size-md),4vw),var(--size-lg));
  --font-size-md: min(max(calc(var(--size-base) + 0.5vw),4vw),var(--size-md));
  --container-padding-inline: clamp(var(--space-base), var(--container-padding-increment), var(--space-xxl));
  --color-text-interactive: var(--color-interactive);
  --color-text-inverse: var(--color-inverse);
  --color-icon-interactive: var(--color-interactive);
  --color-icon-inverse: var(--color-inverse);
  --card-padding: clamp(var(--space-sm), 4vw, var(--space-md));
  --card-color-bg: var(--color-bg);
  --btn-padding-inline: var(--space-lg);
  --box-shadow-card: var(--color-border-decorative) 0 2px 3px 0;
  --box-shadow-menu: var(--color-border-default) 0 1px 0.25rem 0;
}

/* latin-ext */
@font-face {
  font-family: '__Lato_de8755';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/f1df658da56627d0-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Lato_de8755';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/4de1fea1a954a5b6-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: '__Lato_de8755';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/162938472036e0a8-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Lato_de8755';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/6d664cce900333ee-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Lato_Fallback_de8755';src: local("Arial");ascent-override: 101.03%;descent-override: 21.80%;line-gap-override: 0.00%;size-adjust: 97.69%
}.__className_de8755 {font-family: '__Lato_de8755', '__Lato_Fallback_de8755';font-style: normal
}

